<template>
  <div id="dialogues">
    <div
      v-if="showPreview"
      style="
        width: 100vw;
        height: 100vh;
        z-index: 10000;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
      "
    >
      <div
        @click="showPreview = false"
        style="
          color: white;
          font-size: 30px;
          padding: 30px 30px 15vh;
          text-align: right;
          cursor: pointer;
        "
      >
        X
      </div>
      <img
        style="max-height: 70vh; max-width: 70vw"
        :src="previewImageUrl"
        alt
        srcset
      />
    </div>
    <div v-if="false" class="content-header">
      <div class="leftInfo">
        <span class="headerName">{{ activePageInfo.headerName }}</span>
        <span>
          <el-select
            v-model="activeBot"
            placeholder="请选择机器人"
            @change="selectBot"
          >
            <el-option
              v-for="item in botList"
              :key="item.apiKey"
              :label="item.name"
              :value="item.apiKey"
            ></el-option>
          </el-select>
        </span>
      </div>
    </div>
    <div
      style="padding: 0; height: 100vh"
      v-show="!isEmpty"
      class="dialogues-content"
    >
      <div v-if="false" class="userList">
        <div class="headerName">用户列表</div>
        <div class="headerName" style="padding: 10px">
          <el-input
            class="search-user"
            v-model="searchUserName"
            placeholder="请输入用户名搜索"
            v-on:input="changeInput()"
            @keyup.enter.native="searchUser()"
          >
            <el-button
              @click="searchUser()"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </div>
        <div class="nodata" v-if="isNoData">暂无数据</div>
        <div class="userListContent" v-if="!isNoData">
          <!-- 用户信息 -->
          <div
            class="userItem"
            v-for="(item, index) in userList"
            :key="index"
            :class="{ current: activeClass === index }"
          >
            <div
              class="userItemContent"
              @click="
                clickUserli(
                  index,
                  item.destId,
                  item.sourceId,
                  true,
                  null,
                  true,
                  item.channel,
                  item.ext == null ? null : item.ext.agentId
                )
              "
            >
              <div class="userName">
                <span class="name">{{
                  item.source == "MINI_PROGRAM_WEB_SOCKET"
                    ? "小程序用户"
                    : item.sourceName
                }}</span>
                <span class="date">{{
                  new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                }}</span>
              </div>
              <div class="lastMessages">
                <p v-if="item.type == 'RADIO'">
                  {{
                    item.content.split("_")[item.content.split("_").length - 1]
                  }}
                </p>
                <p v-else-if="item.type == 'answer_card_template'">
                  【消息卡片】
                </p>
                <p
                  v-else-if="
                    item.type == 'user_action_to_satisfaction' ||
                    item.type == 'user_action_to_unsatisfactory'
                  "
                >
                  {{ JSON.parse(item.content).input }}
                </p>
                <p
                  v-else-if="
                    item.type != 'START_SESSION' && item.type != 'END_SESSION'
                  "
                >
                  {{ item.content }}
                </p>
              </div>
            </div>
            <el-link
              :underline="false"
              type="primary"
              v-if="index == userList.length - 1 && moreUsers"
              @click="getUserList(activeBot, item.sourceId, false, item.pageNo)"
              >更多...</el-link
            >
          </div>
        </div>
      </div>
      <div class="dialog">
        <div class="headerName">对话记录</div>
        <div
          style="height: calc(100vh - 70px); display: block"
          class="dialogContent"
          id="messageContent"
          ref="msgContainer"
        >
          <div v-for="(item, index) in userMessages" :key="index">
            <el-link
              :underline="false"
              v-if="index == 0 && moreMessages"
              type="primary"
              @click="
                clickUserli(
                  activeClass,
                  userList[activeClass].destId,
                  userList[activeClass].sourceId,
                  false,
                  item.id,
                  false,
                  userList[activeClass].channel,
                  userList[activeClass].ext == null
                    ? null
                    : userList[activeClass].ext.agentId
                )
              "
              >更多消息</el-link
            >
            <el-link
              :underline="false"
              v-if="index == 0 && !moreMessages"
              type="primary"
              disabled
              >没有更多消息了</el-link
            >
            <div
              class="message message-kf"
              v-if="item.source == 'WEB_SOCKET' || item.source == 'PWX_SOCKET'"
            >
              <div class="user-detail">
                <div class="media-body">
                  <p
                    v-if="
                      item.type == 'user_action_to_satisfaction' ||
                      item.type == 'user_action_to_unsatisfactory'
                    "
                  >
                    {{ JSON.parse(item.content).input }}
                  </p>
                  <div v-else-if="item.type == 'FORM_SUBMIT'">
                    <FormTemplate
                      :submit="true"
                      :disable="true"
                      :formList="JSON.parse(item.content)"
                      :usePropStyleType="true"
                      :propIsCompany="true"
                    >
                    </FormTemplate>
                  </div>
                  <div v-else-if="item.type == 'VALUE_CONFIRM'">
                    <TextMessage
                      :submit="true"
                      :disable="true"
                      :text="JSON.parse(item.content)"
                    >
                    </TextMessage>
                  </div>
                  <p v-else-if="item.type == 'TEMPLATE_CARD_BUTTON'">
                    {{ JSON.parse(item.content).name }}
                  </p>
                  <div v-else-if="item.type == 'VIDEO'">
                    <video-player
                      class="video-player vjs-custom-skin"
                      :options="videoOptions(JSON.parse(item.content))"
                      style="width: 260px"
                    />
                  </div>
                  <div v-else-if="item.type == 'IMAGE'">
                    <el-image
                      style="width: 35%"
                      :src="JSON.parse(item.content).urls[0]"
                    ></el-image>
                  </div>
                  <p
                    v-else
                    v-html="
                      item.type == 'RADIO'
                        ? item.content.split('_')[
                            item.content.split('_').length - 1
                          ]
                        : item.content
                    "
                  ></p>
                  <span>{{
                    new Date(item.time).Format("yyyy-MM-dd hh:mm:s")
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="message message-kf"
              v-if="item.source == 'WEBSITE_SOCKET'"
            >
              <div class="user-detail">
                <div class="media-body">
                  <p
                    v-if="
                      item.type == 'user_action_to_satisfaction' ||
                      item.type == 'user_action_to_unsatisfactory'
                    "
                  >
                    {{ JSON.parse(item.content).input }}
                  </p>
                  <p
                    v-else
                    v-html="
                      item.type == 'RADIO'
                        ? item.content.split('_')[
                            item.content.split('_').length - 1
                          ]
                        : item.content
                    "
                  >
                  </p>
                  
                  <span>{{
                    new Date(item.time).Format("yyyy-MM-dd hh:mm:s")
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="message message-kf"
              v-if="item.source == 'MINI_PROGRAM_WEB_SOCKET'"
            >
              <div class="user-detail">
                <div class="media-body">
                  <p
                    v-if="
                      item.type == 'user_action_to_satisfaction' ||
                      item.type == 'user_action_to_unsatisfactory'
                    "
                  >
                    {{ JSON.parse(item.content).input }}
                  </p>
                  <p v-else-if="item.type == 'TEMPLATE_CARD_BUTTON'">
                    {{ JSON.parse(item.content).name }}
                  </p>

                  <p
                    v-else
                    v-html="
                      item.type == 'RADIO'
                        ? item.content.split('_')[
                            item.content.split('_').length - 1
                          ]
                        : item.content
                    "
                  ></p>
                  <span>{{
                    new Date(item.time).Format("yyyy-MM-dd hh:mm:s")
                  }}</span>
                </div>
              </div>
            </div>

            <!--  用户满意度满意事件-->
            <div
              class="message"
              v-if="item.type == 'user_action_to_satisfaction'"
            >
              <template>
                <div
                  class="user-detail"
                  v-if="JSON.parse(item.content) != null"
                >
                  <div class="media-body">
                    <p v-html="JSON.parse(item.content).reply_text"></p>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                </div>
              </template>
            </div>

            <!--  用户满意度不满意按钮数据   -->
            <div
              class="message"
              v-if="item.type == 'user_action_to_unsatisfactory'"
            >
              <template>
                <div
                  class="user-detail"
                  v-if="JSON.parse(item.content) != null"
                >
                  <div class="media-body-ps">
                    <p
                      class="other"
                      v-if="JSON.parse(item.content).reply_text != null"
                    >
                      {{ JSON.parse(item.content).reply_text }}
                    </p>
                    <p
                      class="ps"
                      v-if="
                        JSON.parse(item.content).reply_options != null &&
                        JSON.parse(item.content).reply_options.length > 0
                      "
                      v-for="(option, index) in JSON.parse(item.content)
                        .options"
                      :key="index"
                    >
                      {{ option.name }}
                    </p>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span class="botname">{{ userInfor.botName }}</span>
                  </div>
                </div>
              </template>
            </div>

            <!-- 机器人消息 -->
            <template
              v-if="
                (item.source == 'GUORAN_BOT' ||
                  item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER') &&
                item.type != 'user_action_to_satisfaction' &&
                item.type != 'user_action_to_unsatisfactory'
              "
            >
              <div
                class="message"
                v-for="(msg, index) in JSON.parse(item.content)"
                :key="index"
              >
                <div class="user-detail" v-if="msg.content != null && JSON.stringify(msg.content)!='{}' ">
                  <div
                    class="media-body"
                    v-if="
                      (msg.type == 'answer_text' ||
                        msg.type == 'answer_welcometext' ||
                        msg.type == 'answer_correct') &&
                      msg.content.list != null &&
                      msg.content.list[0] != '__welcome__'
                    "
                  >
                    <p
                      v-html="
                        msg.content.list != null ? msg.content.list[0] : ''
                      "
                    ></p>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    class="media-body"
                    v-else-if="msg.type == 'answer_rich_text'"
                  >
                    <p v-html="msg.content.html"></p>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    class="media-body"
                    style="max-width: 350px"
                    v-else-if="msg.type == 'action_confirm_value'"
                  >
                    <TextMessage :disable="true" :text="msg.content">
                    </TextMessage>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    class="media-body"
                    style="max-width: 350px"
                    v-else-if="msg.type == 'action_form'"
                  >
                    <FormTemplate :disable="true" :formList="msg.content"
                                  :usePropStyleType="true"
                                  :propIsCompany="true"
                    >
                    </FormTemplate>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    class="media-body"
                    style="max-width: 350px"
                    v-else-if="msg.type == 'action_feedback'"
                  >
                    <FeedBack
                      :disable="true"
                      :feedBack="msg.content"
                    ></FeedBack>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    class="media-body"
                    style="max-width: 350px"
                    v-else-if="msg.type == 'answer_card_template'"
                  >
                    <TicketMessage
                      @btnClick="templateInfo"
                      :content="msg.content"
                    >
                    </TicketMessage>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>

                  <div
                    class="media-body"
                    v-else-if="
                      msg.type == 'action_question' &&
                      msg.content != null &&
                      msg.content.questions != null &&
                      msg.content.questions.length > 0
                    "
                  >
                    <p v-html="msg.content.questions[0]"></p>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    class="media-body"
                    v-else-if="msg.type == 'answer_rich_text_pro'"
                  >
                    <div v-html="msg.content.html"></div>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    class="media-body"
                    v-else-if="msg.type == 'answer_image'"
                  >
                    <p>
                      <el-image
                        style="width: 35%"
                        :src="msg.content.url"
                      ></el-image>
                    </p>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    v-else-if="msg.type == 'answer_radio'"
                    class="media-body-ps"
                  >
                    <p class="other">{{ msg.content.description }}</p>
                    <p
                      class="ps"
                      v-for="(option, index) in msg.content.options"
                      :key="index"
                    >
                      {{ option.name }}
                    </p>
                    <span style="margin-left: 15px; margin-top: 5px">{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    v-else-if="msg.type == 'action_satisfaction'"
                    class="media-body-ps"
                  >
                    <p class="other">{{ msg.content.satisfactionDes }}</p>
                    <p
                      class="ps"
                      v-for="(option, index) in ['满意', '不满意']"
                      :key="index"
                    >
                      {{ option }}
                    </p>
                  </div>
                  <div
                    v-else-if="msg.type == 'answer_video'"
                    class="media-body"
                  >
                    <p>
                      <video-player
                        class="video-player vjs-custom-skin"
                        :options="videoOptions(msg.content)"
                        style="height: auto"
                      />
                    </p>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>

                  <div v-else-if="msg.type == 'action_transfer'">
                    <!--                                        机器人回复为转人工组件内容 -->
                  </div>
                  <div v-else-if="msg.type == 'action_transfer_2_ewechat'">
                    <!--                                        机器人回复为转企业微信客服组件内容 -->
                  </div>
                  <div v-else>
                    <p>{{ msg }}</p>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div v-if="false" class="userInfo">
        <div class="headerName">用户信息</div>
        <div class="userInfoContent">
          <div class="userBaseInfor">
            <p
              class="icon"
              v-if="userInfor.user == null || userInfor.user.avatar == null"
            >
              <i class="arsenal_icon arsenaluser-circle"></i>
            </p>
            <p
              class="icon"
              v-if="userInfor.user != null && userInfor.user.avatar"
            >
              <el-avatar :src="userInfor.user.avatar"></el-avatar>
            </p>
            <p class="username">
              {{
                userInfor == null || userInfor.user == null
                  ? ""
                  : userInfor.user.userName
              }}
            </p>
            <div class="time">
              <i class="arsenal_icon arsenalclock"></i>
              <span>{{
                userInfor == null
                  ? ""
                  : new Date(userInfor.lastMessageTime).Format(
                      "yyyy-MM-dd hh:mm:ss"
                    )
              }}</span>
            </div>
          </div>
          <!-- 客户访问 -->
          <div class="customerAccess">
            <div class="headerTitle">客户访问</div>
            <div class="itemBot">
              <div class="itemName">所属机器人：</div>
              <div class="itemNContent">{{ userInfor.botName }}</div>
            </div>
            <div class="itemBot">
              <div class="itemName">用户来源：</div>
              <div class="itemNContent">{{ userInfor.userSource }}</div>
            </div>
            <div class="itemBot">
              <div class="itemName">所属应用：</div>
              <div class="itemNContent">{{ userInfor.agentName }}</div>
            </div>
          </div>
          <!-- 客户信息 -->
          <div class="customerAccess">
            <div class="headerTitle">客户信息</div>
            <div class="itemUser">
              <div class="itemName">姓 名：</div>
              <div class="itemNContent">
                {{
                  userInfor == null || userInfor.user == null
                    ? ""
                    : userInfor.user.userName
                }}
              </div>
            </div>
            <div class="itemUser">
              <div class="itemName">性 别：</div>
              <div
                class="itemNContent"
                v-if="
                  userInfor == null || userInfor.user == null
                    ? ''
                    : userInfor.user.gender == 2
                "
              >
                女
              </div>
              <div
                class="itemNContent"
                v-if="
                  userInfor == null || userInfor.user == null
                    ? ''
                    : userInfor.user.gender == 1
                "
              >
                男
              </div>
            </div>
            <div class="itemUser" v-if="accountType !== 'WITHOUT_TEMPLATE'">
              <div class="itemName">手机号：</div>
              <div class="itemNContent">
                {{
                  userInfor == null || userInfor.user == null
                    ? ""
                    : userInfor.user.mobile
                }}
              </div>
            </div>
            <div class="itemUser" v-if="accountType !== 'WITHOUT_TEMPLATE'">
              <div class="itemName">邮 箱：</div>
              <div class="itemNContent">
                {{
                  userInfor == null || userInfor.user == null
                    ? ""
                    : userInfor.user.email
                }}
              </div>
            </div>
            <div class="itemUser">
              <div class="itemName">公 司：</div>
              <div class="itemNContent"></div>
            </div>
            <div class="itemUser" v-if="accountType !== 'WITHOUT_TEMPLATE'">
              <div class="itemName">职 务：</div>
              <div class="itemNContent"></div>
            </div>
            <div class="itemUser" v-if="accountType !== 'WITHOUT_TEMPLATE'">
              <div class="itemName">地 址：</div>
              <div class="itemNContent">
                {{
                  userInfor == null || userInfor.user == null
                    ? ""
                    : userInfor.user.address
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="empty">
      <div v-show="userList.length == 0 && isEmpty" class="empty-rg">
        <div class="image-outer">
          <img class="empty-image" src="../../assets/images/u1016.png" />
        </div>
        <div class="empty-tips">
          <span class="empty-title">当前还没有聊天记录哦</span>
          <span class="empty-warning"
            >如果想要接收聊天消息，请先将机器人接入到您的应用中哦。</span
          >
          <span class="empty-des"
            >您可以在【我的机器人】页面，选择想要接入的机器人，再进入</span
          >
          <span class="empty-des">机器人接入页面完成机器人接入哦。</span>
          <span class="empty-des">
            <el-link type="primary" @click="toBotIndex()"
              >前往我的机器人</el-link
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
// import { videoPlayer } from "vue-video-player";
import TicketMessage from "askbot-dragon/src/components/message/TicketMessage";
import TextMessage from "askbot-dragon/src/components/message/TextMessage";
import FormTemplate from "askbot-dragon/src/components/formTemplate";
import FeedBack from "askbot-dragon/src/components/feedBack";
export default {
  name: "dialogues",
  components: {
    TicketMessage,
    TextMessage,
    FormTemplate,
    FeedBack,
  },
  data() {
    return {
      showPreview: false,
      previewImageUrl: "",
      activePageInfo: {
        headerName: "对话记录",
      },
      searchUserName: "",
      activeClass: 0,
      activeBot: {},
      currentUser: {},
      botList: [],
      moreMessages: false,
      moreUsers: false,
      apiKey: [],
      userList: [
        // {
        //     sourceName: "",
        //     content: "",
        //     destId: "",
        //     source: "",
        //     sourceId: "",
        //     time: "",
        //     type: "",
        // },
      ],
      userMessages: [],
      userInfor: {
        user: [{ userName: "" }],
      },
      page: 1,
      page_count: "",
      accountType: "NORMAL",
      loginSuccessTimes: "",
      searching: false,
      isEmpty: false,
      isNoData: false,
      lastUserList: [],
    };
  },
  methods: {
    autoLayout() {
      document.documentElement.style.fontSize =
        document.documentElement.clientWidth / 7.5 + "px";
      window.addEventListener(
        "resize",
        function () {
          document.documentElement.style.fontSize =
            document.documentElement.clientWidth / 7.5 + "px";
        },
        false
      );
    },
    previewImage(_this) {
      this.showPreview = true;
      this.previewImageUrl = _this.src;
    },
    videoOptions(videoContent) {
      return {
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: videoContent.url,
          },
        ],
        width: "260",
        height: "160",
      };
    },
    changeInput() {
      console.log("e:" + this.searchUserName);
      if (this.searchUserName == "") {
        this.getUserList(this.activeBot, null, true, null, true);
        this.searching = false;
        this.isNoData = false;
      }
    },
    searchUser() {
      this.searching = true;
      this.isEmpty = false;
      this.getUserList(this.activeBot, null, true, null, true);
    },
    scrollBottom() {
      this.$nextTick(() => {
        let container = document.getElementById("messageContent");
        let scrollHeight = container.scrollHeight;
        console.debug("滚动高度", scrollHeight);
        container.scrollTop = scrollHeight;
      });
    },
    scrollToTop() {
      this.$nextTick(() => {
        let container = document.getElementById("messageContent");
        container.scrollTop = 0;
      });
    },
    //跳转至机器人页面
    toBotIndex: function () {
      this.$router.push({
        name: "botIndex",
      });
    },
    selectBot(apiKey) {
      this.userList = [];
      this.getUserList(apiKey, null, true, null, true);
    },
    getUserList(apiKey, latestSourceId, showFirstUserInfo, pageNo, clear) {
      const BATCH_USER_LIST_SIZE = 20;
      //const BATCH_USER_LIST_SIZE = 2;
      let url =
        this.requestUrl.messageController.getChatbotMessageList +
        "" +
        apiKey +
        "?" +
        "size=" +
        BATCH_USER_LIST_SIZE;
      if (latestSourceId != null) {
        url = url + "&latestSourceId=" + latestSourceId;
      }
      if (pageNo == null) {
        pageNo = 1;
      }
      url = url + "&pageNo=" + pageNo + "&nameLike=" + this.searchUserName;
      let that = this;
      if (clear == true) {
        this.userList = [];
      }
      this.FetchGet(url).then((res) => {
        if (res.code === "0") {
          if (res.data != null && res.data.length > 0) {
            that.isEmpty = false;
            let userList = res.data;
            console.log(userList);
            // that.userList.push(userList);
            for (
              let i = 0,
                len =
                  userList.length > BATCH_USER_LIST_SIZE
                    ? BATCH_USER_LIST_SIZE
                    : userList.length;
              i < len;
              i++
            ) {
              that.userList.push(userList[i]);
            }
            that.lastUserList = that.userList;
            if (res.data.length > BATCH_USER_LIST_SIZE) {
              that.moreUsers = true;
            } else {
              that.moreUsers = false;
            }
            if (showFirstUserInfo != null && showFirstUserInfo == true) {
              let firstUser = that.userList[0];
              //   that.clickUserli(
              //     0,
              //     firstUser.destId,
              //     firstUser.sourceId,
              //     true,
              //     null,
              //     true,
              //     firstUser.channel,
              //     firstUser.ext == null ? null : firstUser.ext.agentId
              //   );
            }
          } else if (res.data.length == 0 && that.searching == true) {
            that.userList = that.lastUserList;
            console.log("aaa");
            that.isEmpty = false;
            that.isNoData = true;
          } else if (res.data.length == 0 && !that.searching) {
            that.isEmpty = true;
          }
        } else {
          this.$message.error(res.message);
        }
        this.loading = true;
      });
    },
    // 获取机器人列表
    getBotList() {
      this.FetchGet(this.requestUrl.bot.getBotList, {
        page: 1,
        size: 100,
      }).then((res) => {
        if (res.code === "0") {
          this.botList = res.data.list;
          this.activeBot = this.botList[0].apiKey;
          this.getUserList(this.activeBot, null, true, null, true);
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    //点击用户列表
    clickUserli(
      index,
      destId,
      sourceId,
      scroll,
      lastMessageId,
      clearHistory,
      channel,
      agentId
    ) {
      console.log("=============channel:" + channel + ",agentId:" + agentId);
      this.activeClass = index;
      if (clearHistory != null && clearHistory == true) {
        this.userMessages = [];
      }
      console.log(destId, sourceId);
      let url =
        this.requestUrl.messageController.getUserDialog +
        "" +
        destId +
        "/" +
        sourceId +
        "?size=11";
      //let url = this.requestUrl.messageController.getUserDialog+ "" +destId + "/" +sourceId;
      if (lastMessageId != null) {
        url = url + "&lastMessageId=" + lastMessageId;
      }
      let that = this;
      this.FetchGet(url).then((res) => {
        if (res.code === "0") {
          let userMessages = res.data;
          //that.userMessages = res.data;
          if (userMessages != null) {
            if (userMessages.length > 10) {
              let slice = userMessages.slice(0, 10);
              for (let i = userMessages.length - 1; i >= 0; i--) {
                if (userMessages[i].type == null) {
                  let tampArr = JSON.parse(userMessages[i].content);
                  if (tampArr != null) {
                    tampArr.forEach((cell) => {
                      if (cell.type == "answer_rich_text_pro") {
                        cell.content.html = cell.content.html.replace(
                          /onclick="__rich_text_pro_button_onclick\(this\)"/g,
                          ""
                        );
                      }
                    });
                  }
                  userMessages[i].content = JSON.stringify(tampArr);
                }
                that.userMessages.unshift(userMessages[i]);
              }
              // console.log('wwh' + that.userMessages);
              // that.userMessages = ;
              that.moreMessages = true;
            } else {
              for (let i = userMessages.length - 1; i >= 0; i--) {
                that.userMessages.unshift(userMessages[i]);
              }
              that.moreMessages = false;
            }
          }
          //切换用户时定位到记录顶部
          if (scroll) {
            //that.scrollBottom();
            setTimeout(function () {
              that.scrollBottom();
            }, "1000");
            //that.scrollToTop();
          }
        } else {
          this.$message.error(res.message);
        }
        this.loading = true;
      });
      // this.getUserInformation(destId, sourceId, channel, agentId);
    },
    //获取用户详细对话
    getUserDialog() {},
    //获取用户详细对话
    getUserInformation(destId, sourceId, channel, agentId) {
      let url =
        this.requestUrl.messageController.getUserInformation +
        "" +
        destId +
        "/" +
        sourceId;
      if (channel != null) {
        url = url + "?channel=" + channel;
        if (agentId != null) {
          url = url + "&agentId=" + agentId;
        }
      } else {
        if (agentId != null) {
          url = url + "?agentId=" + agentId;
        }
      }
      console.log("=============user info url:" + url);
      this.FetchGet(url).then((res) => {
        if (res.code === "0") {
          this.userInfor = res.data;
        } else {
          this.$message.error(res.message);
        }
        this.loading = true;
      });
    },
  },
  mounted() {
    this.autoLayout();
    window.previewImage = this.previewImage;
    // this.getBotList();

    // this.getUserList(this.$route.query.destId, null, true, null, true);
    this.accountType = localStorage.getItem("accountType");
    //this.getChatbotMessageList();
    console.log(this.$route.query);

    // destId=ce506d4ff6c741459e34c9ba9f83ae24&sourceId=wwac60444bf20f31b1_JiuLi
    // http://localhost:8081/#/tbchat?destId=bf76bf60cb9b4451ad4d83b8f528d498&sourceId=wwac60444bf20f31b1_JiuLi
    let destId = this.$route.query.destId;
    let sourceId = this.$route.query.sourceId;
    this.userList.push({
      destId: destId,
      sourceId: sourceId,
    });
    this.clickUserli(0, destId, sourceId, true, null, false, "", "");
  },
};
</script>

<style lang="less" scoped>
@import "./../../assets/less/dialogues/tbChat.less";
.leftInfo {
  display: flex !important;
  justify-content: space-between !important;
}
</style>
